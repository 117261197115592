
import { associates, IAssociate, IGroup } from "@/api";
import { permissions } from "@/mixins";
import { Actions } from "@/store/models";
import { getYear } from "@/utils";
import Vue from "vue";
import EditableLabel from "../common/EditableLabel.vue";
import UserIcon from "../common/UserIcon.vue";

export default Vue.extend({
  components: { EditableLabel, UserIcon },
  name: "GroupCard",
  mixins: [permissions],
  props: {
    group: Object as () => IGroup,
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    created: function (): string {
      return getYear(this.group.created_datetime);
    },
  },
  methods: {
    viewProfile() {
      this.$router.push(`/groups/${this.group.id}`);
    },
    viewUsers() {
      console.log(this.$route.path);
      this.$router.push(this.$route.path + `/${this.group.id}/users`);
    },
    async deleteGroup() {
      try {
        await associates.deleteGroup(this.group.id);
        await this.$store.dispatch(Actions.GetCompanyGroups);
      } catch (error) {
        this.$store.dispatch(Actions.DisplayError, error);
      }
    },
    view() {
      this.$router.push(this.$route.path + `/${this.group.id}/users`);
    },
    async updateName(name: string) {
      try {
        await associates.updateGroup(this.group.id, { name });
        await this.$store.dispatch(Actions.GetCompanyGroups);
      } catch (error) {
        this.$store.dispatch(Actions.DisplayError, error);
      }
    },
    getAssociate(id: number) {
      return this.$store.state.company?.associates?.find(
        (a: IAssociate) => a.id === id
      );
    },
  },
  watch: {
    group: {
      immediate: true,
      handler() {
        this.group?.associate_associates_groups.sort((a: any, b: any) => {
          if (a.associate.first_name < b.associate.first_name) {
            return -1;
          }
          if (a.associate.first_name > b.associate.first_name) {
            return 1;
          }
          return 0;
        });
      },
    },
    selected() {
      this.$emit("selectionUpdated")
    }
  },
});
