
import { associates, IAssociate, IGroup } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "NewGroupDailog",
  props: {
    dialog: Boolean,
  },
  data() {
    return {
      groupName: "",
      selectedAssociates: [],
    };
  },
  computed: {
    associates: function (): IAssociate[] {
      return this.$store.state.company.associates;
    },
    groups: function (): IGroup[] {
      return this.$store.state.company.groups || [];
    },
  },
  methods: {
    async createGroup() {
      if (!this.groupName) {
        this.$store.dispatch(Actions.DisplayError, "Please enter group name.");
        return;
      }
      if (this.groups.find((g) => g.name === this.groupName)) {
        this.$store.dispatch(
          Actions.DisplayError,
          "A group with this name already exists."
        );
        return;
      }
      try {
        await associates.addGroup({
          name: this.groupName,
          company: {
            id: this.$store.state.company.id,
          },
          associates: this.selectedAssociates,
        });
        await this.$store.dispatch(Actions.GetCompanyGroups);
        await this.$store.dispatch(Actions.GetCompanyAssociates);
        this.groupName = "";
        this.selectedAssociates = [];
        this.$emit("toggleDialog", false);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
