
import { IGroup } from "@/api";
import Vue from "vue";
import GroupCard from "@/components/groups/GroupCard.vue";
import NewGroupModal from "@/components/groups/NewGroupModal.vue";
import { permissions } from "@/mixins";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { Actions } from "@/store/models";
import CompanyHeader from "@/components/common/gssiAdmin/CompanyHeader.vue";
export default Vue.extend({
  name: "Groups",
  components: {
    GroupCard,
    NewGroupModal,
    ConfirmationDialog,
    CompanyHeader,
  },
  mixins: [permissions],
  data() {
    return {
      headers: [
        { text: "USER", value: "name" },
        { text: "USERNAME", value: "username" },
        { text: "COMPANY", value: "company" },
        { text: "ROLE", value: "role" },
        { text: "LAST UPDATED", value: "dateEdited" },
        { text: "EDITED BY", value: "editedBy" },
      ],
      searchQuery: "",
      newGroupDialog: false,
      deleteGroupsDialog: false,
      disableDelete: true,
    };
  },
  computed: {
    groups: function (): IGroup[] {
      return this.$store.state.company.groups || [];
    },
    filteredGroups: function (): IGroup[] {
      return this.groups.filter((group: IGroup) => {
        return group.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
    },
  },
  created() {
    this.$store.dispatch(Actions.GetCompanyGroups);
  },
  methods: {
    toggleNewGroupDialog(dialog: boolean) {
      this.newGroupDialog = dialog;
    },
    getGroupRef(id: number): string {
      return `g-${id}`;
    },
    deleteGroups() {
      const refs: any = this.$refs;
      this.filteredGroups.forEach((g) => {
        const id = this.getGroupRef(g.id);
        const ele = refs[id][0];
        if (ele.selected) {
          ele.deleteGroup();
        }
      });
      this.disableDelete = true;
    },
    selectionUpdated() {
      const refs: any = this.$refs;
      let selected = false;
      this.filteredGroups.forEach((g) => {
        const id = this.getGroupRef(g.id);
        const ele = refs[id][0];
        if (ele.selected) {
          selected = true;
        }
      });
      this.disableDelete = !selected;
    },
  },
});
